const moment = require('moment')
const _ = require('lodash')
const ics = require('ics')
const slugify = require('slugify')

const format = 'dddd, MMMM Do [at] h:mma'

const normalizedGrade = number => {
  if (number === -1) {
    return 'Pre-K'
  } if (number === 0) {
    return 'K'
  } else {
    let ordinals = ['th', 'st', 'nd', 'rd']
    let value = number % 100
    return number + (ordinals[ (value - 20) % 10 ] || ordinals[value] || ordinals[0])
  }
}

const currency = cents => {
  return (cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 })
}

const sessions = sessions => {
  return `${ sessions } session${ sessions === 1 ? '' : 's' }`
}

const dateToArray = date => {
  return [date.get('year'), date.get('month') + 1, date.get('date'), date.get('hour'), date.get('minute')]
}

const generateIcs = (camp, session) => {
  if (session.startDate === null || session.endDate === null) return null

  const totalDays = session.endDate.diff(session.startDate, 'days')

  const startDateEndTime = [session.startDate.get('year'), session.startDate.get('month'), session.startDate.get('date'), session.endDate.get('hour'), session.endDate.get('minute')]

  let events = []

  for (let currentDay = 0; currentDay <= totalDays; currentDay++) {
    const startTime = moment(session.startDate).add(currentDay, 'day')
    const endTime = moment(startDateEndTime).add(currentDay, 'day')

    events.push({
      start: dateToArray(startTime.utc()),
      end: dateToArray(endTime.utc()),
      title: `${ camp.name } - ${ session.name }`,
      description: camp.summary,
      // location: 'Folsom Field, University of Colorado (finish line)', // camp address
      url: camp.link, // camp url
      // geo: { lat: 40.0095, lon: 105.2669 }, // camp latitude && longitude
      categories: camp.tags,
      status: 'CONFIRMED',
      // organizer: { name: 'Simple Summers', email: 'hello@simplesummers.com' }
    })
  }

  const { error, value } = ics.createEvents(events)

  if (error) {
    console.log(error); return null
  } else {
    return value
  }
}

class Camp {
  constructor (data) {
    this.id = data.contentful_id
    this.name = data.name
    this.category = data.category
    this.tags = data.tags
    this.summary = data.summary
    this.link = data.link
    this.updatedAt = data.updatedAt
    this.description = data.description ? data.description.childContentfulRichText.html : null
    this.organization = data.organization ? new Organization(data.organization) : null

    this.slug = `/camp/${ this.id }/${ slugify([this.organization.name, this.name].join(' '), { replacement: '-', remove: /[^A-Za-z0-9 ]/g, lower: true }) }/`

    this.sessions = data.sessions ? data.sessions.map(sessionData => new Session({ ...sessionData, ...{ campSlug: this.slug }, ...{ campName: this.name }, ...{ organizationName: this.organization ? this.organization.name : null } })) : []

    const sessionStartDates = _.compact(this.sessions.map(session => session.startDate))
    this.sessionStartDate = (sessionStartDates.length > 0) ? moment.min(sessionStartDates) : null

    const sessionEndDates = _.compact(this.sessions.map(session => session.endDate))
    this.sessionEndDate = (sessionEndDates.length > 0) ? moment.max(sessionEndDates) : null

    this.image = data.images ? data.images.map(imageData => imageData.file.url) : null

    this.cities = Array.from(new Set(_.flatMap(this.sessions, session => session.city))).sort()
  }

  ages () {
    const allAges = _.pull(_.flatMap(this.sessions, session => [session.minAge, session.maxAge]), null)
    const noMinAge = _.includes(_.map(this.sessions, session => session.minAge), null)
    const noMaxAge = _.includes(_.map(this.sessions, session => session.maxAge), null)
    const minAge = noMinAge ? null : _.min(allAges)
    const maxAge = noMaxAge ? null : _.max(allAges)
    if (minAge !== null && maxAge !== null) {
      return (minAge === maxAge) ? `age ${ maxAge }` : `ages ${ minAge } to ${ maxAge }`
    } else if (minAge !== null) {
      return `all ages ${ minAge } and up`
    } else if (maxAge !== null) {
      return `all ages up to ${ maxAge }`
    } else {
      return 'all ages'
    }
  }

  grades () {
    const allGrades = _.pull(_.flatMap(this.sessions, session => [session.minGrade, session.maxGrade]), null)
    const noMinGrade = _.includes(_.map(this.sessions, session => session.minGrade), null)
    const noMaxGrade = _.includes(_.map(this.sessions, session => session.maxGrade), null)
    const minGrade = noMinGrade ? null : _.min(allGrades)
    const maxGrade = noMaxGrade ? null : _.max(allGrades)
    if (minGrade !== null && maxGrade !== null) {
      return (minGrade === maxGrade) ? (minGrade === 0) ? `Kindergarden only` : `${ normalizedGrade(maxGrade) } grade` : `grades ${ normalizedGrade(minGrade) } to ${ normalizedGrade(maxGrade) }`
    } else if (minGrade && maxGrade === null) {
      return `all grades ${ normalizedGrade(minGrade) } and up`
    } else if (minGrade === null && maxGrade) {
      return `all grades up to ${ normalizedGrade(maxGrade) }`
    } else {
      return null
    }
  }

  prices () {
    const allPrices = _.flatMap(this.sessions, session => session.price)
    const minPrice = Math.min(...allPrices)
    const maxPrice = Math.max(...allPrices)
    return (minPrice !== null) ? (minPrice === maxPrice) ? (minPrice === 0) ? 'free' : `starts at ${ currency(minPrice) }` : `from ${ currency(minPrice) } to ${ currency(maxPrice) }` : null
  }

  dates () {
    const sessionStartDates = _.compact(this.sessions.map(session => session.startDate))
    const sessionEndDates = _.compact(this.sessions.map(session => session.endDate))
    const sessionStartDate = (sessionStartDates.length > 0) ? moment.min(sessionStartDates) : null
    const sessionEndDate = (sessionEndDates.length > 0) ? moment.max(sessionEndDates) : null

    if (sessionStartDate != null && sessionEndDate != null) {
      let format = 'dddd, MMMM Do [at] h:mma'
      return `  ${ sessions(this.sessions.length) } from ${ moment(sessionStartDate).format(format) } to ${ moment(sessionEndDate).format(format) }.`
    } else {
      return ''
    }
  }

  printableCity () {
    if ( this.cities.reduce((lhs, rhs, index, array) => lhs + (index < array.length - 1 ? ', ' : array.length === 2 ? ' and ' : ', and ') + rhs) == null ) {
        return '';
    } else {
        return (this.cities.length > 0) ? `  Located in ${ this.cities.reduce((lhs, rhs, index, array) => lhs + (index < array.length - 1 ? ', ' : array.length === 2 ? ' and ' : ', and ') + rhs) }.` : ''
    }
  }

  snapshot () {
    return (this.sessions.length > 0) ? `${ [this.grades() || this.ages(), this.prices()].filter(Boolean).join(', ') }.${ this.printableCity() }${ this.dates() }` : 'There\'s currently no session information available for this camp, but please check back soon!'
  }
}

class Organization {
  constructor (data) {
    this.id = data.contentful_id
    this.name = data.name
    this.accountTier = data.accountTier
  }
}

class Camper {
  constructor (data) {
    this.id = data.id
    this.name = data.name
    this.birthMonth = data.birthMonth
    this.birthYear = data.birthYear
  }

  age () {
    return moment('06-01', 'MM-DD').year(moment().year()).diff(moment({ year: this.birthYear, month: this.birthMonth }), 'years', true)
  }
}

class Session {
  constructor (data) {
    this.id = data.contentful_id
    this.name = data.name
    this.campName = data.campName
    this.campSlug = data.campSlug
    this.organizationName = data.organizationName
    this.minGrade = (data.minGrade !== null) ? data.minGrade : null
    this.maxGrade = (data.maxGrade !== null) ? data.maxGrade : null
    this.minAge = (this.minGrade == null) ? data.minAge : this.minGrade + 5
    this.maxAge = (this.maxGrade == null) ? data.maxAge : this.maxGrade + 6
    this.price = (data.price !== null) ? data.price : null
    this.gender = data.gender || null
    this.startDate = data.startDate ? moment(data.startDate) : null
    this.endDate = data.endDate ? moment(data.endDate) : null
    this.location = data.address ? data.address.location : null
    this.city = data.address ? data.address.city : null
  }

  ages () {
    if (this.minAge != null && this.maxAge != null) {
      return (this.minAge === this.maxAge) ? `age ${ this.maxAge }` : `ages ${ this.minAge } to ${ this.maxAge }`
    } else if (this.minAge != null) {
      return `all ages ${ this.minAge } and up`
    } else if (this.maxAge != null) {
      return `all ages up to ${ this.maxAge }`
    } else {
      return 'all ages'
    }
  }

  grades () {
    if (this.minGrade != null && this.maxGrade != null) {
      return (this.minGrade === this.maxGrade) ? (this.minGrade === 0) ? `Kindergarden only` : `${ normalizedGrade(this.maxGrade) } grade` : `grades ${ normalizedGrade(this.minGrade) } to ${ normalizedGrade(this.maxGrade) }`
    } else if (this.minGrade != null) {
      return `all grades ${ normalizedGrade(this.minGrade) } and up`
    } else if (this.maxGrade != null) {
      return `all grades up to ${ normalizedGrade(this.maxGrade) }`
    } else {
      return null
    }
  }

  prices () {
    return (this.price != null) ? (this.price === 0) ? 'free' : `starts at ${ currency(this.price) }` : null
  }

  dates () {
    if (this.startDate != null && this.endDate != null) {
      return `  From ${ moment(this.startDate).format(format) } to ${ moment(this.endDate).format(format) }.`
    } else {
      return ''
    }
  }

  printableCity () {
    return (this.city != null) ? `  Located in ${ this.city }.` : ''
  }

  startDateTimestamp () {
    return (this.startDate) ? moment(this.startDate).startOf('day').unix() : null
  }

  endDateTimestamp () {
    return (this.endDate) ? moment(this.endDate).endOf('day').unix() : null
  }

  geoloc () {
    return (this.location) ? { lat: this.location.lat, lng: this.location.lon } : null
  }

  isValid () {
    return this.startDate && this.endDate && this.price != null
  }

  snapshot (withDate = false) {
    return `${ [this.category, this.grades() || this.ages(), this.prices()].filter(Boolean).join(', ') }.${ this.printableCity() }${ withDate ? this.dates() : '' }`
  }

  calendarEvent () {
    return {
      title: `${ this.organizationName } - ${ this.campName }`,
      description: 'Added from simplesummers.com',
      endTime: this.endDate.format(),
      location: this.address ? this.address.formattedAddress : '',
      startTime: this.startDate.format(),
    }
  }
}

module.exports = {
  normalizedGrade: normalizedGrade,
  currency: currency,
  sessions: sessions,
  generateIcs: generateIcs,
  Camp: Camp,
  Organization: Organization,
  Session: Session,
  Camper: Camper
}
